@mixin font-face($family, $file, $weight) {
    @font-face {
        font-family: $family;
        src: local($file), url($file) format('truetype');
        font-weight: $weight;
        font-style: normal;
    }
}

@include font-face('Roboto', 'Roboto-Light.ttf', 300);
@include font-face('Roboto', 'Roboto-Regular.ttf', 400);
@include font-face('Roboto', 'Roboto-Medium.ttf', 500);
@include font-face('Roboto', 'Roboto-Bold.ttf', 700);
