@use '@angular/material' as mat;
@use '@angular-clan/font/dist/VmIcons.scss';
@use "@ag-grid-community/styles" as ag;

@include mat.all-component-typographies();
@include mat.core();

// Material design palette generator http://mcg.mbitson.com/#!?mcgpalette0=%23009dc6
$adition-tv-material-palette: (
    50 : #e0f5fd,
    100 : #b2e5f8,
    200 : #7fd5f2,
    300 : #4bc4e9,
    400 : #24b9e2,
    500 : #0aaeda,
    600 : #009dc6,
    700 : #008aab,
    800 : #007792,
    900 : #005666,
    A100 : #ceedff,
    A200 : #9bdaff,
    A400 : #68c7ff,
    A700 : #4fbdff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adition-app-primary: mat.m2-define-palette($adition-tv-material-palette, 600);
$adition-app-accent: mat.m2-define-palette($adition-tv-material-palette, 300, 400, 500);

@import "@angular-clan/core/styles/utils/apply-line-height";
$typography: apply-core-line-height(mat.m2-define-typography-config());

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$adition-app-theme: mat.m2-define-light-theme(
        (
            color: (primary: $adition-app-primary,
                accent: $adition-app-accent,
            ),
            typography: $typography,
        )
);
@include mat.all-component-themes($adition-app-theme);

@import "@angular-clan/core/styles/utils/core-theme";
@include core-theme($adition-app-theme);

@import "@angular-clan/core/styles/core-styles";
@import "@angular-clan/core/styles/custom-styles/flex-migration-styles";
@import "@angular-clan/core/styles/custom-styles/deprecated-flex-migration-styles";
@import "@angular-clan/core/styles/custom-styles/breakpoint-mixin";
:root {
    --default-app-color: #009DC6;
    --base-grey-color: #757575;
    --content-border-radius: 4px;
    --content-border-color: #70707066;
    --info-color: #42A6F5;
    --warning-color: #FFC006;
    --error-color: #E43834;
    --description-color: var(--default-app-color);
    --mat-list-hover-highlight: #E4E4E4;
    --light-primary: mat.m2-get-color-from-palette($adition-app-primary, 50);


    // Core variables
    --core-default-app-color: var(--default-app-color);
    --core-content-margin: 0px;
    --core-content-border-radius: 0px;
    --core-content-box-border-radius: var(--content-border-radius);
    --core-secondary-toolbar-left-gradient-color: var(--default-app-color);
    --core-secondary-toolbar-right-gradient-color: var(--default-app-color);
    --core-sidebar-logo: url(assets/images/virtualminds-logo-color.svg);
    --core-watermark-image: url(assets/images/virtualminds-logo-white-bildmarke.svg);
    --core-content-box-border-color: var(--default-app-color);
    --core-sidenav-color: #F7F7F7;
    --core-sidenav-entry-font-color: #000000;
    --core-sidenav-entry-active-font-color: #FFFFFF;
    --core-sidenav-entry-active-bg-color: #3e3e3e;
    --core-background-color: #DADADA;
    --core-sidenav-submenu-background: #EAEAEA;
    --core-select-max-height: 330px;
    --core-app-color-strong: #018aab;
}

html,
body {
    height: 100%;
}

body {
    margin: unset;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    overflow: hidden;
}

.mat-horizontal-content-container {
    overflow: unset !important;
    padding: unset !important;
}


.snack-style {
    .mdc-snackbar__surface {
        --mdc-snackbar-supporting-text-color: white;
    }

    &--success {
        --mdc-snackbar-container-color: var(--default-app-color);
    }

    &--error {
        --mdc-snackbar-container-color: var(--error-color);

        white-space: pre-wrap;
    }
}

.menu-panel--no-padding .mat-mdc-menu-content {
    padding: 0;
}

.chip-list__list-item--hide-checkbox .mdc-list-item__end {
    display: none;
}

.chip-list__list-item--selected .mdc-list-item__content {
    :focus .mdc-list-item__primary-text,
    :hover .mdc-list-item__primary-text,
    .mdc-list-item__primary-text {
        --mdc-list-list-item-label-text-color: var(--base-grey-color);
        --mdc-list-list-item-hover-label-text-color: var(--base-grey-color);
        --mdc-list-list-item-focus-label-text-color: var(--base-grey-color);
    }
}

mat-chip-option {
    button {
        text-transform: none;
    }
}

@include ag.grid-styles((
    theme: alpine,
    --ag-alpine-active-color: #009DC6,
    --ag-font-family: ('Roboto', sans-serif),
    --ag-font-size: mat.m2-font-size($typography, 'body-2'),
    --ag-range-selection-background-color: rgba(0, 0, 0, 0.04),
    --ag-selected-row-background-color:rgba(0, 0, 0, 0.16),
    --ag-odd-row-background-color: null,
    --ag-row-hover-color:var(--ag-range-selection-background-color),
    --ag-range-selection-border-color:var(--ag-active-color),
    --ag-range-selection-highlight-color:rgba(var(--ag-active-color), 0.2),
    --ag-secondary-foreground-color: #757575,
    --ag-header-background-color: #fff,
    --ag-border-radius: 0px,
    --ag-borders: 'none',
    --ag-border-color: #E0E0E0,
    --ag-borders-critical: solid 1px,
    --ag-row-border-color:var(--ag-border-color),
));
.ag-theme-alpine {
    .ag-popup-editor {
        background-color: var(--ag-control-panel-background-color);
        padding: 0;
    }

    .ag-root-wrapper {
        border-radius: var(--content-border-radius);
    }

    .ag-layout-auto-height .ag-center-cols-viewport,
    .ag-layout-auto-height .ag-center-cols-clipper,
    .ag-layout-auto-height .ag-center-cols-container,
    .ag-layout-print .ag-center-cols-clipper,
    .ag-layout-print .ag-center-cols-container {
        min-height: 400px;
    }

    .grid-table-cell-editable:hover {
        cursor: pointer;
    }

    .grid-table-cell-readonly {
        pointer-events: none;
    }

    .ag-row-hover .grid-table-cell-editable::after {
        @extend .vm-icons;
        content: 'edit';
        position: absolute;
        top: 8px;
        right: 2px;
        color: #757575;
    }

    // Links
    a {
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

// @TODO - O2M-4190: should we really adjust styles here or should we rather <atv-toggle-button as e.g. in line item capping or <atv-inverse-toggle> as in other targetings?
.mdc-button {
    font-weight: 500;
    --mdc-fab-container-color: white;
}

.selection-menu.mat-mdc-selection-list {
    padding: 0;
}

.localization-button {
    border-radius: 50%;
    border: 0.5px solid #626262;

    svg {
        transform: scale(1.5);
        color: #000000;
    }
}

// Style for infinite button
.infinite-button {
    &.mat-mdc-fab {
        background-color: var(--mat-list-hover-highlight);
        @include mat.elevation(0);

        &:hover {
            @include mat.elevation(0);
        }

        &.mdc-fab:not(.mdc-ripple-upgraded):focus {
            @include mat.elevation(0);
        }
    }

    &-icon-reverse {
        color: var(--default-app-color)
    }
}

.toggle-button {
    mat-button-toggle-group {
        height: 56px;
    }

    .mat-button-toggle,
    .mat-button-toggle-button {
        height: 100%;
    }

    &--primary-color {
        .mat-button-toggle-checked {
            --mat-standard-button-toggle-selected-state-background-color: var(--default-app-color);
            --mat-legacy-button-toggle-selected-state-background-color: var(--default-app-color);
            --mat-standard-button-toggle-selected-state-text-color: #ffffff;
            --mat-legacy-button-toggle-selected-state-text-color: #ffffff;
            font-weight: 500;
        }
    }

    &--secondary-color {
        .mat-button-toggle-checked {
            --mat-standard-button-toggle-selected-state-background-color: #4dbad74f;
            --mat-legacy-button-toggle-selected-state-background-color: #4dbad74f;
            --mat-standard-button-toggle-selected-state-text-color: var(--default-app-color);
            --mat-legacy-button-toggle-selected-state-text-color: var(--default-app-color);
            font-weight: 500;
        }
    }

    &--label-padding {
        .mat-button-toggle-label-content {
            padding: 0 12px;
        }
    }
}

mat-button-toggle.mat-button-toggle-checked .mat-pseudo-checkbox {
    display: none;
}

.day-picker__toggle-button {
    .mat-button-toggle-label-content {
        position: relative;
        top: -12px;
        padding: 0 !important;
    }
}

.datepicker {
    &--no-input-filed {
        opacity: 0;
        width: 0;
        height: 32px;

        .mat-mdc-form-field-bottom-align,
        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }
    }
}

.date-range-picker-panel {
    height: 354px !important;
}

.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
    --mdc-fab-container-color: var(--default-app-color);
}

.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
    --mdc-fab-container-color: white;
}

.vm-icons {
    &.mat-body-1 {
        margin-bottom: 0;
    }
}

.highlight-filter-panel {
    background-color: var(--light-primary);
}

input {
    caret-color: #000000 !important;
}

.sidenav__watermark-image {
    bottom: 0 !important;
    right: 0 !important;
    opacity: 1 !important;
}

.mat-form-field-disabled .mat-mdc-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0.42) 33%, #c2c7cc 0) !important;
    background-size: 1px 100% !important;
    background-repeat: repeat-x !important;
}

.mat-input-disabled-custom-layout .mat-mdc-form-field-flex {
    background-color: rgba(0, 0, 0, 0.02);
}

.progress-bar__grey-progress .mat-progress-bar-buffer {
    background: var(--base-grey-color);
}

.ag-row.table-disable-row {
    opacity: 38%;
    pointer-events: none;
}

/**
 * Global styles for an mat-dialog are defined here
 */
.dialog-container {
    &--small {
        .mat-mdc-dialog-container {
            min-width: 30rem;
            max-width: 35rem;
        }
    }

    &--no-padding {
        .mdc-dialog .mat-mdc-dialog-content {
            padding: 0;
        }
    }

    &:not(&--no-padding) {
        .mdc-dialog .mat-mdc-dialog-content {
            padding: 0 24px;
        }
    }

    .mdc-dialog {
        &__title {
            padding-left: 0;
        }
    }

    .mat-mdc-dialog-content {
        .margin-form {
            margin-top: 24px;
        }
    }
}

.bubble-chart {
    &__group {
        fill-opacity: 0.2;
    }

    &__group-label {
        fill-opacity: 1;
    }

    &__item {
        fill-opacity: 1;
    }

    &__item-label {
        fill-opacity: 0.8;
    }

    &__item-tooltip {
        fill-opacity: 0.9;
    }
}

.full-width {
    width: 100%;
}

.base-list--message-box {
    padding: 16px;
}

.adition-tv-report-form-wrapper__has-report-model {
    .save-bar-content-wrapper__content {
        margin-bottom: 1rem !important;
    }
}

.custom-checkbox-color {
    --mat-checkbox-label-text-color: white;

    .mdc-checkbox {
        &__background {
            --mdc-checkbox-unselected-icon-color: white;
            --mdc-checkbox-selected-checkmark-color: white;
            --mdc-checkbox-unselected-focus-icon-color: white;
            --mdc-checkbox-unselected-hover-icon-color: white;
            --mdc-checkbox-unselected-pressed-icon-color: white;
        }
    }
}

.custom-badge-color .mat-badge-content {
    background-color: var(--warning-color);
    color: var(--warning-color);
}

.atv-virtual-scroll {
    &__viewport {
        height: 50px;
    }

    .cdk-virtual-scroll-content-wrapper {
        display: flex;
        flex-direction: row;
    }
}

.mat-mdc-slide-toggle {
    /* handle colors */
    //unselected
    --color-unselected-handle: #fafafa;
    --mdc-switch-unselected-handle-color: var(--color-unselected-handle);
    --mdc-switch-unselected-focus-handle-color: var(--color-unselected-handle);
    --mdc-switch-unselected-hover-handle-color: var(--color-unselected-handle);
    --mdc-switch-unselected-pressed-handle-color: var(--color-unselected-handle);

    /* track colors */
    //unselected
    --color-unselected-track: #00000061;
    --mdc-switch-unselected-track-color: var(--color-unselected-track);
    --mdc-switch-unselected-focus-track-color: var(--color-unselected-track);
    --mdc-switch-unselected-hover-track-color: var(--color-unselected-track);
    --mdc-switch-unselected-pressed-track-color: var(--color-unselected-track);
}

.hide-number-arrows {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.centered-content {
    max-width: 1200px;
}

.actions-toolbar-container {
    @include mat.button-density(-2);
}

.core-msa__panel {
    padding: 0 !important;
}

.content-box {
    &__content {
        .ag-aria-description-container {
            position: relative;
        }
    }
}

.generated-report__group {
    mat-tab-header {
        box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, .1);
    }
}

.brand-and-product-row {
    .mdc-text-field {
        height: 48px !important;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-text-field-wrapper {
        background-color: #EFEFEF !important;
    }

    .mdc-line-ripple::after {
        border-bottom-width: 0 !important;
    }

    .mdc-text-field--filled .mdc-line-ripple::before {
        border-bottom-color: var(--mdc-filled-text-field-active-indicator-color);
        border: none;
    }

    &:hover .mat-mdc-form-field-focus-overlay {
        background-color: rgba(224, 245, 253, -0.686275) !important; 
    }

    &:hover .mat-mdc-text-field-wrapper{
        background-color: rgba(224, 245, 253, -0.686275) !important; 
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: #EFEFEF !important;
    }
}

.product-row {
    --mat-form-field-container-height: 48px;

    .mdc-text-field {
        height: 47px !important;
    }

    .mat-mdc-form-field-infix {
        padding-bottom: unset;
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-form-field-focus-overlay {
        background-color: #EFEFEF !important;
    }


    .mat-mdc-text-field-wrapper {
        background-color: #EFEFEF !important;
    }

    .mdc-text-field--filled .mdc-line-ripple::before {
        border-bottom-color: var(--mdc-filled-text-field-active-indicator-color);
        border: none;
    }
}

.advertiser-preview__select-all {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
        border-color: var(--base-grey-color) !important;
        background-color: var(--base-grey-color) !important;
    }
}

.advertiser-exclusion-scroll-container{
    .accordion__item-body{
        overflow: visible !important;
    }
}

.atv-company-delete-dioalog {
    .mdc-dialog__title {
        padding-left: 24px;
    }
}

.atv-exclusions-tree {
    .ag-header { display: none; }
}