@use '@angular-clan/core/styles/custom-styles/breakpoint-mixin' as breakpoint;

.vm-column-gap-1 {
    column-gap: 1rem;
}

.vm-column-gap-2 {
    column-gap: 2rem;
}

.vm-flex-60 {
    flex: 1 1 60%;
    max-width: 60%;
}

.vm-flex-90 {
    flex: 1 1 90%;
    max-width: 90%;
}

.vm-flex-40-gt-md {
    @include breakpoint.gt-md() {
        flex: 1 1 40%;
        max-width: 40%;
    }
}

.vm-flex-80-gt-md {
    @include breakpoint.gt-md() {
        flex: 1 1 80%;
        max-width: 80%;
    }
}

.vm-layout-justify-end-xs {
    @include breakpoint.xs() {
        justify-content: end;
    }
}
